





























import Vue from 'vue';
import Component from 'vue-class-component';
import Auth, { CognitoUser } from '@aws-amplify/auth';
import { Group } from '@jca/flightpath-components/src/models/CognitoUser';

// Define the component in class-style
@Component({ components: {} })
export default class Home extends Vue {
  user: CognitoUser | undefined;
  userGroups: string[] = [];

  mounted() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.user = user;
      })
      .catch(() => (this.user = undefined));

    Auth.currentSession()
      .then(session => {
        const payload = session.getIdToken().payload;
        const groups: Array<string> = payload['cognito:groups'];
        this.userGroups = groups;
      })
      .catch(err => {
        this.userGroups = [];
        console.error(err);
      });
  }

  get isFlightPathAdmin(): boolean {
    if (this.userGroups.length == 0) return false;
    return this.userGroups.indexOf(Group.FlightPathAdmin) > -1;
  }
}
