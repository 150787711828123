














































































import Vue from 'vue';
import Component from 'vue-class-component';
import NewMachine from '@/components/NewMachine.vue';
import MachineCertificate from '@/components/MachineCertificate.vue';
import RevokeCertificate from '@/components/RevokeCertificate.vue';
import CreateCertificate from '@/components/CreateCertificate.vue';
import { Inject, Prop } from 'vue-property-decorator';
import { Container } from 'inversify';
import { FlightPathServiceInterface } from '@jca/flightpath-components/src/services/FlightPathServiceInterface';
import SERVICE_IDENTIFIER from '@jca/flightpath-components/src/services/identifiers';
import { Account } from '@jca/flightpath-components/src/models/Account';
import { MachineCredential, MachineCertificateStatus } from '@jca/flightpath-components/src/models/MachineCredential';
import { MachineCredentialCertificate } from '@jca/flightpath-components/src/models/MachineCredentialCertificate';
import { FlightPathAdminServiceInterface } from '@jca/flightpath-components/src/services/FlightPathAdminServiceInterface';
import { FlightPathAxios } from '@/services/api';
import { AxiosError, AxiosResponse } from 'axios';
import { MachineStatus } from '@/types/machine-status';
import { UpdateResponse } from '@/types/update-response';
import { MachineCredentialStatus } from '@/types/machine-credential-status';

// Define the component in class-style
/* eslint-disable @typescript-eslint/no-explicit-any */
@Component({ components: { NewMachine, MachineCertificate, RevokeCertificate, CreateCertificate } })
export default class MachineCredentials extends Vue {
  @Prop({ required: true }) account!: Account;
  loading = true;
  windowSize = {
    x: 0,
    y: 0
  };
  headers = [
    { text: 'Machine Name', value: 'MachineName', align: 'left' },
    { text: 'Machine Id', value: 'MachineId', align: 'left' },
    { text: 'Installed Version', value: 'InstalledVersion', align: 'left' },
    { text: 'Install date', value: 'InstallDate', align: 'left' },
    { text: 'Available Version', value: 'AvailableVersion', align: 'left' },
    { text: 'Certificate Status', value: 'CertificateStatus', align: 'center' },
    { text: 'Certificate Actions', value: 'actions', align: 'left', sortable: false }
  ];
  machineCredentials: MachineCredential[] = [];
  machinesUpdate: MachineStatus[] = [];
  machines: MachineCredentialStatus[] = [];
  machineCredentialCertificate: MachineCredentialCertificate | null = null;
  newMachineDialog = false;
  newCertificateDialog = false;
  snackBar = false;
  snackBarText = '';
  machineCredentialDialog = false;
  revokeCertDialog = false;
  selectedMachine: MachineCredential | null = null;
  formatDate = new Intl.DateTimeFormat("en-CA", { timeStyle: "short", dateStyle: "short", hour12: false });

  backToAccount() {
    this.$router.back();
  }

  getOperation(status: number): boolean {
    return status == MachineCertificateStatus.Active ? true : false;
  }

  newMachineCredential(credential: MachineCredentialCertificate){
    this.machineCredentialCertificate = credential;
    this.initialize();
  }

  createCertificateDialog(item: any) {
    this.selectedMachine = item;
    this.newCertificateDialog = true;
  }

  revokeCertificateDialog(item: MachineCredential) {
    this.selectedMachine = item;
    this.revokeCertDialog = true;
  }

  getCertificateStatus(status: number): string {
    return MachineCertificateStatus[status];
  }

  disableRevokeAction(status: number): boolean {
    return status === MachineCertificateStatus.None || status === MachineCertificateStatus.Revoked ? true : false;
  }

  async getMachineCredentials() {
    if (this.flightPathAdminService != undefined) await this.flightPathAdminService.updateToken();

    try {
      const response = await this.flightPathAdminService?.getMachineCredentials(this.account.AccountId);
      this.machineCredentials = JSON.parse(JSON.stringify(response?.data)) as MachineCredential[];
    } catch (err: any) {
      this.errorHandler(err);
    }
  }

  async getUpdateReport() {
    try{
      const response = await new FlightPathAxios().getInstance().get<UpdateResponse[]>(`/exxact/update-report?CustomerID=${this.account.AccountId}`);
      this.machinesUpdate = response.data.map(update => ({
        machineId: update.MachineSoftwareUpdate?.machine_id,
        machineName: update.MachineSoftwareUpdate?.machine_name,
        installedVersion: update.InstalledMachineConfig?.installed_version,
        installDate: update.InstalledMachineConfig ? this.formatDate.format(new Date(update.InstalledMachineConfig?.install_date)) : "",
        availableVersion: update.InstalledMachineConfig?.last_available_version,
      }));
    }
    catch (err: any) {
      this.errorHandler(err);
    }
  }

  mergeMachineData(){
    this.machines = this.machineCredentials.map(item => {
      const update = this.machinesUpdate.find(update => update.machineId === item.MachineId);
      if (update)
        return {
          MachineId: item.MachineId,
          AccountId: item.AccountId,
          MachineName: item.MachineName,
          CertificateStatus: item.CertificateStatus,
          DeployedTimeUTC: item.DeployedTimeUTC,
          UpdateTimeUTC: item.UpdateTimeUTC,
          InstalledVersion: update.installedVersion, 
          InstallDate: update.installDate,
          AvailableVersion: update.availableVersion
         } as MachineCredentialStatus;
      else
      return {
          MachineId: item.MachineId,
          AccountId: item.AccountId,
          MachineName: item.MachineName,
          CertificateStatus: item.CertificateStatus,
          DeployedTimeUTC: item.DeployedTimeUTC,
          UpdateTimeUTC: item.UpdateTimeUTC,
          InstalledVersion: "", 
          InstallDate: "",
          AvailableVersion: ""
        } as MachineCredentialStatus;
    });
  }

  errorHandler(err: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    console.error(err);
    const response = (err as AxiosError).response as AxiosResponse<any>;
    const message = response.data;
    const displayMessage = Object.prototype.hasOwnProperty.call(message, 'error') ? message.error : message;
    this.snackBarText = `Error: ${displayMessage}`;
    this.snackBar = true;
  }

  async initialize() {
    this.loading = true;
    await this.getMachineCredentials();
    await this.getUpdateReport();
    this.mergeMachineData();
    this.loading = false;
  }

  // @Inject()
  private flightPathService: FlightPathServiceInterface | undefined = undefined;
  private flightPathAdminService: FlightPathAdminServiceInterface | undefined = undefined;

  // IoC container provided from App.ts is injected here
  @Inject('container')
  private container!: Container;

  created(): void {
    this.flightPathService = this.container.get<FlightPathServiceInterface>(SERVICE_IDENTIFIER.FLIGHT_PATH);
    this.flightPathAdminService = this.container.get<FlightPathAdminServiceInterface>(SERVICE_IDENTIFIER.FLIGHT_PATH_ADMIN);
  }

  // Class properties will be component data
  onResize() {
    this.windowSize = { x: window.innerWidth, y: window.innerHeight };
  }

  async mounted() {
    this.onResize();
    if (this.flightPathService != undefined) await this.flightPathService.updateToken();
    if (this.flightPathAdminService != undefined) await this.flightPathAdminService.updateToken();
    this.initialize();
  }
}
