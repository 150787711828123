































































/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Component from 'vue-class-component';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import container from './app.container';
import { awsConfiguration } from './aws-configuration';
import Vue from 'vue';

@Component({
  provide: { container: container }
})
export default class App extends Vue {
  signedIn = false;
  user: CognitoUser | undefined;
  userInfo: any = {};
  items: any = [];
  userGroups: string[] = [];
  mini = true;

  get currentRouteName(): string {
    return this.$route.meta.title;
  }

  async beforeCreate() {
    Hub.listen('auth', data => {
      console.log('data:', data);
      const { payload } = data;
      if (payload.event === 'signIn') {
        this.signedIn = true;
        this.$router.push('/home');
        this.setupSession();
      }
      if (payload.event === 'signOut') {
        this.$router.push('/auth');
        this.signedIn = false;
        this.userInfo = null;
      }
    });
  }

  private setupSession() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.signedIn = true;
        this.user = user;
      })
      .catch(err => {
        this.signedIn = false;
        console.error(err);
      });

    Auth.currentUserInfo()
      .then(res => {
        this.userInfo = res.attributes;
      })
      .catch(err => {
        this.signedIn = false;
        console.error(err);
      });

    Auth.currentSession()
      .then(session => {
        const payload = session.getIdToken().payload;
        const groups: Array<string> = payload['cognito:groups'];
        this.userGroups = groups;
      })
      .catch(err => {
        this.signedIn = false;
        this.userGroups = [];
        console.error(err);
      });
  }

  async mounted() {
    this.setupSession();
  }
}
