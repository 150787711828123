









































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, Watch } from 'vue-property-decorator';
import { Container } from 'inversify';
import { FlightPathServiceInterface } from '@jca/flightpath-components/src/services/FlightPathServiceInterface';
import SERVICE_IDENTIFIER from '@jca/flightpath-components/src/services/identifiers';
import { Convert, Group, User } from '@jca/flightpath-components/src/models/CognitoUser';
import { SortHelper } from '@/services/SortHelper';

// Define the component in class-style
/* eslint-disable @typescript-eslint/no-explicit-any */
@Component
export default class AdminUsers extends Vue {
  totalUsers = 0;
  loading = true;
  creatingAccount = false;
  datapointsPerPage = 10;
  datapointsCurrentPage = 1;
  pageTokens = new Map<number, string>();
  private options: any = {
    itemsPerPage: this.datapointsPerPage,
    page: this.datapointsCurrentPage
  };
  windowSize = {
    x: 0,
    y: 0
  };
  adminUsers: User[] = [];
  newFlightPathAdminFormValid = '';
  newFlightPathAdminEmail = '';
  snackBar = false;
  snackBarText = '';
  createNewFlightPathAdminDialog = false;

  @Watch('options.page')
  onPageChange(value: number): void {
    this.getDataFromApi(value);
  }

  @Watch('options.itemsPerPage')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onPerPageChange(value: number, oldValue: number): void {
    this.pageTokens.clear();
    if (this.options.page == 1) this.getDataFromApi(1);
    this.options.page = 1;
  }

  headers = [{ text: 'User Email', value: 'Email', align: 'left' }];

  getDataFromApi(page: number) {
    this.loading = true;
    this.getUsers(page).then(data => {
      this.loading = false;
    });
  }

  async getUsers(page: number) {
    if (this.flightPathService != undefined) await this.flightPathService.updateToken();
    let token = '';
    if (this.pageTokens.has(page)) token = this.pageTokens.get(page)?.toString() ?? '';

    const response = await this.flightPathService?.getAllUsersGroup(
      this.options.itemsPerPage > 0 ? this.options.itemsPerPage : 50,
      token,
      Group.FlightPathAdmin
    );
    const groupResponse = Convert.toListUsersInGroupResponse(JSON.stringify(response?.data));

    if (groupResponse.NextToken == null) {
      this.totalUsers = this.options.itemsPerPage * (page - 1) + groupResponse.Users.length;
    } else {
      this.totalUsers = this.options.itemsPerPage * (page + 1);
    }

    if (!this.pageTokens.has(page + 1) && groupResponse.NextToken != null) this.pageTokens.set(page + 1, groupResponse.NextToken);

    this.adminUsers = groupResponse.Users.sort((a, b) => {
      return SortHelper.compareStrings(this.getEmail(a), this.getEmail(b));
    });
  }

  initialize() {
    this.getDataFromApi(1);
  }

  userAttribute(user: User, attribute: string): string {
    let returnValue = '';
    user.Attributes?.forEach(value => {
      if (value.Name === attribute) {
        returnValue = value.Value;
      }
    });
    return returnValue;
  }

  getEmail(user: User): string {
    return this.userAttribute(user, 'email');
  }

  getAccountId(user: User): string {
    return this.userAttribute(user, 'custom:accountid');
  }

  async createNewFlightPathAdmin() {
    this.snackBarText = '';
    this.creatingAccount = true;

    try {
      const response = await this.flightPathService?.addUser(this.newFlightPathAdminEmail, Group.FlightPathAdmin);
      this.snackBarText = this.$t('success') as string;
      console.log(response);
    } catch (err: unknown) {
      console.error(err);
      this.snackBarText = `${err}`;
    } finally {
      this.snackBar = true;
      this.createNewFlightPathAdminDialog = false;
      this.creatingAccount = false;
    }

    await this.getUsers(0);
  }

  // @Inject()
  private flightPathService: FlightPathServiceInterface | undefined = undefined;
  // IoC container provided from App.ts is injected here
  @Inject('container')
  private container!: Container;

  created(): void {
    this.flightPathService = this.container.get<FlightPathServiceInterface>(SERVICE_IDENTIFIER.FLIGHT_PATH);
  }

  // Class properties will be component data
  onResize() {
    this.windowSize = { x: window.innerWidth, y: window.innerHeight };
  }
  async mounted() {
    this.onResize();
    if (this.flightPathService != undefined) await this.flightPathService.updateToken();
    this.initialize();
  }
}
