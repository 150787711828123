import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import 'vue-class-component/hooks'; // import hooks type to enable auto-complete
import vuetify from './plugins/vuetify';
import { Auth } from '@aws-amplify/auth';
import awsExports from '@/aws-exports';
import i18n from './i18n';
import flightpath from '@jca/flightpath-components';

Auth.configure(awsExports);
Vue.filter('yesno', function(value: boolean) {
  return value ? 'Yes' : 'No';
});
Vue.config.productionTip = false;
Vue.config.errorHandler = err => {
  console.log('Exception: ', err);
};

Vue.use(flightpath);
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');
