import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VContainer,{staticClass:"fill-height fill-width",attrs:{"fluid":""}},[_c(VCard,{staticClass:"mx-auto",staticStyle:{"margin-top":"50px"},attrs:{"max-width":"400"}},[_c(VToolbar,{attrs:{"color":"secondary","dark":""}},[_c(VToolbarTitle,{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t('settings.title')))])],1),_c(VCardActions,[_c('div',{staticClass:"sign-out"},[_c(VBtn,{staticClass:"justify-center",attrs:{"color":"primary"},on:{"click":function($event){return _vm.signOut()}}},[_vm._v("Sign Out")])],1)]),_c(VList,{attrs:{"two-line":"","subheader":""}},[_c(VSubheader,[_vm._v(_vm._s(_vm.$t('settings.general')))]),_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('settings.application_version')))]),_c(VListItemSubtitle,[_vm._v("V "+_vm._s(_vm.appVersion))])],1)],1),_c(VListItem,[_c(VListItemContent,[_c(VSwitch,{attrs:{"label":_vm.$t('settings.local_mode')},model:{value:(_vm.localMode),callback:function ($$v) {_vm.localMode=$$v},expression:"localMode"}})],1)],1),_c(VListItem,{directives:[{name:"show",rawName:"v-show",value:(_vm.localMode),expression:"localMode"}]},[_c(VListItemContent,[_c(VTextField,{attrs:{"type":"url","label":_vm.$t('settings.flightpath_address'),"rules":_vm.addressRules,"required":""},model:{value:(_vm.flightPathIp),callback:function ($$v) {_vm.flightPathIp=$$v},expression:"flightPathIp"}})],1)],1)],1),_c(VDivider),_c(VCardActions,[_c(VBtn,{attrs:{"color":"primary","disabled":!_vm.valid},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t('save')))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }