import { Module, VuexModule } from 'vuex-module-decorators';

@Module({ name: 'global' })
export default class GlobalStore extends VuexModule {
  packageVersion = process.env.VUE_APP_VERSION;

  get version(): string | undefined {
    return this.packageVersion;
  }
}
