import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import Resize from 'vuetify/lib/directives/resize';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-2"},[_c(VRow,[_c(VCol,{staticClass:"text-center mb-3"},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){_vm.newMachineDialog = true}}},[_vm._v(" Create New Machine ")])],1)],1),_c(VCard,[_c(VCardTitle,[_c(VIcon,{staticClass:"mr-3",attrs:{"color":"black","size":"30"},on:{"click":_vm.backToAccount}},[_vm._v("mdi-keyboard-backspace")]),_vm._v(" Machines ("+_vm._s(_vm.account.Name)+") ")],1),_c(VRow,{directives:[{def: Resize,name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticStyle:{"height":"calc(100% - 70px)"},attrs:{"align":"center","justify":"center","no-gutters":""}},[_c(VCol,{staticClass:"fill-height d-xl-flex d-sm-flex d-lg-flex d-md-flex flex-column",attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"height":_vm.windowSize.y - 270,"headers":_vm.headers,"footer-props":{
            itemsPerPageOptions: [10, 25]
          },"items":_vm.machines,"loading":_vm.loading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" ")]}},{key:"item.CertificateStatus",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCertificateStatus(item.CertificateStatus))+" ")]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-2",attrs:{"color":"action-button-color"},on:{"click":function($event){return _vm.createCertificateDialog(item)}}},on),[_vm._v(" mdi-certificate ")])]}}],null,true)},[(_vm.getOperation(item.CertificateStatus))?_c('span',[_vm._v("Recreate")]):_c('span',[_vm._v("Create")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-2",attrs:{"disabled":_vm.disableRevokeAction(item.CertificateStatus),"color":"action-button-color"},on:{"click":function($event){return _vm.revokeCertificateDialog(item)}}},on),[_vm._v(" mdi-window-close ")])]}}],null,true)},[_c('span',[_vm._v("Revoke")])])]}}])})],1)],1)],1),(_vm.newMachineDialog)?_c('NewMachine',{attrs:{"account":_vm.account},on:{"newmachinecredential":_vm.newMachineCredential},model:{value:(_vm.newMachineDialog),callback:function ($$v) {_vm.newMachineDialog=$$v},expression:"newMachineDialog"}}):_vm._e(),(_vm.newCertificateDialog)?_c('CreateCertificate',{attrs:{"machine":_vm.selectedMachine},on:{"newmachinecredential":_vm.newMachineCredential},model:{value:(_vm.newCertificateDialog),callback:function ($$v) {_vm.newCertificateDialog=$$v},expression:"newCertificateDialog"}}):_vm._e(),(_vm.machineCredentialCertificate)?_c('MachineCertificate',{model:{value:(_vm.machineCredentialCertificate),callback:function ($$v) {_vm.machineCredentialCertificate=$$v},expression:"machineCredentialCertificate"}}):_vm._e(),(_vm.revokeCertDialog)?_c('RevokeCertificate',{attrs:{"machine":_vm.selectedMachine},on:{"update":_vm.initialize},model:{value:(_vm.revokeCertDialog),callback:function ($$v) {_vm.revokeCertDialog=$$v},expression:"revokeCertDialog"}}):_vm._e(),_c(VSnackbar,{scopedSlots:_vm._u([{key:"action",fn:function(ref){
          var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.snackBar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackBar),callback:function ($$v) {_vm.snackBar=$$v},expression:"snackBar"}},[_vm._v(" "+_vm._s(_vm.snackBarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }