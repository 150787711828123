





















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, Prop } from 'vue-property-decorator';
import { Container } from 'inversify';
import SERVICE_IDENTIFIER from '@jca/flightpath-components/src/services/identifiers';
import { MachineCredential } from '@jca/flightpath-components/src/models/MachineCredential';
import { FlightPathAdminServiceInterface } from '@jca/flightpath-components/src/services/FlightPathAdminServiceInterface';
import { AxiosError, AxiosResponse } from 'axios';
import { Console } from 'console'; //ROMER - REMOVE IT
import { Account } from '@jca/flightpath-components/src/models/Account';
import { Convert, Group, User } from '@jca/flightpath-components/src/models/CognitoUser';

// Define the component in class-style
/* eslint-disable @typescript-eslint/no-explicit-any */
@Component
export default class DeleteAccount extends Vue {
  @Prop() value!: boolean;
  @Prop() account!: Account;

  loading = false;
  windowSize = {
    x: 0,
    y: 0
  };
  confirmationText = '';
  snackBar = false;
  snackBarText = '';
  userEmails: string[] = [];

  async deleteAccount(item: Account) {
    if (this.flightPathAdminService != undefined) await this.flightPathAdminService.updateToken();
    this.snackBarText = '';

    try {
      this.loading = true;
      console.log(item);
      await this.flightPathAdminService?.deleteAccount(item.AccountId);
      this.snackBarText = 'Success';
      this.$emit('input', null);
    } catch (err: any) {
      this.errorHandler(err);
    } finally {
      this.loading = false;
      this.snackBar = true;
    }
    this.$emit('update');
  }

  async getUserEmails() {
    try {
      const response = await this.flightPathAdminService?.getAllUserEmails(this.account.AccountId);
      this.userEmails = JSON.parse(JSON.stringify(response?.data)) as string[];
    } catch (err) {
      console.error(err);
    }
  }

  errorHandler(err: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    console.error(err);
    const response = (err as AxiosError).response as AxiosResponse<any>;
    const message = response.data;
    console.error(JSON.stringify(message));
    const displayMessage = Object.prototype.hasOwnProperty.call(message, 'error') ? message.error : message;
    this.snackBarText = `Error: ${displayMessage}`;
  }

  // @Inject()
  private flightPathAdminService: FlightPathAdminServiceInterface | undefined = undefined;

  // IoC container provided from App.ts is injected here
  @Inject('container')
  private container!: Container;

  created(): void {
    this.flightPathAdminService = this.container.get<FlightPathAdminServiceInterface>(SERVICE_IDENTIFIER.FLIGHT_PATH_ADMIN);
  }

  // Class properties will be component data
  onResize() {
    this.windowSize = { x: window.innerWidth, y: window.innerHeight };
  }

  async mounted() {
    this.onResize();
    if (this.flightPathAdminService != undefined) await this.flightPathAdminService.updateToken();
    await this.getUserEmails();
  }
}
