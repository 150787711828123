































import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, Prop } from 'vue-property-decorator';
import { Container } from 'inversify';
import { FlightPathServiceInterface } from '@jca/flightpath-components/src/services/FlightPathServiceInterface';
import SERVICE_IDENTIFIER from '@jca/flightpath-components/src/services/identifiers';
import { Account } from '@jca/flightpath-components/src/models/Account';
import { MachineCredential } from '@jca/flightpath-components/src/models/MachineCredential';
import { MachineCredentialCertificate } from '@jca/flightpath-components/src/models/MachineCredentialCertificate';
import { FlightPathAdminServiceInterface } from '@jca/flightpath-components/src/services/FlightPathAdminServiceInterface';
import { Guid } from 'guid-typescript';
import { AxiosError, AxiosResponse } from 'axios';

// Define the component in class-style
/* eslint-disable @typescript-eslint/no-explicit-any */
@Component
export default class NewMachine extends Vue {
  @Prop() account!: Account;
  @Prop() value!: boolean;

  loading = false;
  windowSize = {
    x: 0,
    y: 0
  };
  machineCredentialCertificate: MachineCredentialCertificate | null = null;
  newMachineFormValid = '';
  newMachineName = '';
  snackBar = false;
  snackBarText = '';

  
  fieldRequired(): (data: string) => void {
  return (v) => !!v || 'Field is required';
}

  machineNameValid(): (data: string) => void  {
    return (v) =>  /^[a-zA-Z0-9.,@_:#/-]{1,50}$/.test(v) || 'Machine name only can contain: [a-zA-Z0-9.,@_:#/-]';
  }

  machineNameRules: ((data: string) => void)[] = [this.fieldRequired(), this.machineNameValid()];

  async createNewMachineCredential() {
    if (this.flightPathAdminService != undefined) await this.flightPathAdminService.updateToken();
    this.snackBarText = '';
    const machineId = Guid.createEmpty().toString();

    try {
      this.loading = true;
      const response = await this.flightPathAdminService?.AddMachineCredential(this.account.AccountId, machineId, this.newMachineName);
      this.snackBarText = 'Success';
      this.machineCredentialCertificate = response?.data ? response.data : null;
      console.log(response);
      this.$emit('input', false);
    } catch (err: any) {
     this.errorHandler(err);
    } finally {
      this.loading = false;
      this.snackBar = true;
    }
    this.$emit('newmachinecredential', this.machineCredentialCertificate);
  }

  errorHandler(err: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    console.error(err);
    const response = (err as AxiosError).response as AxiosResponse<any>;
    const message = response.data;
    console.error(JSON.stringify(message));
    const displayMessage = Object.prototype.hasOwnProperty.call(message, 'error') ? message.error : message;
    this.snackBarText = `Error: ${displayMessage}`;
  }

  // @Inject()
  private flightPathService: FlightPathServiceInterface | undefined = undefined;
  private flightPathAdminService: FlightPathAdminServiceInterface | undefined = undefined;

  // IoC container provided from App.ts is injected here
  @Inject('container')
  private container!: Container;

  created(): void {
    this.flightPathService = this.container.get<FlightPathServiceInterface>(SERVICE_IDENTIFIER.FLIGHT_PATH);
    this.flightPathAdminService = this.container.get<FlightPathAdminServiceInterface>(SERVICE_IDENTIFIER.FLIGHT_PATH_ADMIN);
  }

  // Class properties will be component data
  onResize() {
    this.windowSize = { x: window.innerWidth, y: window.innerHeight };
  }

  async mounted() {
    this.onResize();
    if (this.flightPathService != undefined) await this.flightPathService.updateToken();
    if (this.flightPathAdminService != undefined) await this.flightPathAdminService.updateToken();
  }
}
