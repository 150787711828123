import { Container } from 'inversify';
import 'reflect-metadata';
import { FlightPathService } from '@/services/flightpathService';
import { FlightPathServiceInterface } from '@jca/flightpath-components/src/services/FlightPathServiceInterface';
import SERVICE_IDENTIFIER from '@jca/flightpath-components/src/services/identifiers';
import { FlightPathAdminServiceInterface } from '@jca/flightpath-components/src/services/FlightPathAdminServiceInterface';
import { FlightPathAdminService } from '@/services/flightpathAdminService';
// eslint-disable-next-line prefer-const
let container: Container = new Container();

container.bind<FlightPathServiceInterface>(SERVICE_IDENTIFIER.FLIGHT_PATH).to(FlightPathService);
container.bind<FlightPathAdminServiceInterface>(SERVICE_IDENTIFIER.FLIGHT_PATH_ADMIN).to(FlightPathAdminService);

export default container;
