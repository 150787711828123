











































import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, Prop } from 'vue-property-decorator';
import { Container } from 'inversify';
import { FlightPathServiceInterface } from '@jca/flightpath-components/src/services/FlightPathServiceInterface';
import SERVICE_IDENTIFIER from '@jca/flightpath-components/src/services/identifiers';
import { MachineCredential } from '@jca/flightpath-components/src/models/MachineCredential';
import { MachineCredentialCertificate } from '@jca/flightpath-components/src/models/MachineCredentialCertificate';
import { FlightPathAdminServiceInterface } from '@jca/flightpath-components/src/services/FlightPathAdminServiceInterface';
import { AxiosError, AxiosResponse } from 'axios';

// Define the component in class-style
/* eslint-disable @typescript-eslint/no-explicit-any */
@Component
export default class RevokeCertificate extends Vue {
  @Prop() value!: boolean;
  @Prop() machine!: MachineCredential;


  loading = false;
  windowSize = {
    x: 0,
    y: 0
  };
  machineCredentialCertificate: MachineCredentialCertificate | null = null;
  confirmationText = '';
  snackBar = false;
  snackBarText = '';

  async revoke(item: MachineCredential) {
    if (this.flightPathAdminService != undefined) await this.flightPathAdminService.updateToken();
    this.snackBarText = '';

    try {
      this.loading = true;
      const response = await this.flightPathAdminService?.RevokeMachineCertificate(item.AccountId, item.MachineId);
      this.snackBarText = 'Success';
      console.log(response);
      this.$emit('input', null);
    } catch (err: any) {
      this.errorHandler(err);
    } finally {
      this.loading = false;
      this.snackBar = true;
    }
    this.$emit('update');
  }

  errorHandler(err: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    console.error(err);
    const response = (err as AxiosError).response as AxiosResponse<any>;
    const message = response.data;
    console.error(JSON.stringify(message));
    const displayMessage = Object.prototype.hasOwnProperty.call(message, 'error') ? message.error : message;
    this.snackBarText = `Error: ${displayMessage}`;
  }
 
  // @Inject()
  private flightPathService: FlightPathServiceInterface | undefined = undefined;
  private flightPathAdminService: FlightPathAdminServiceInterface | undefined = undefined;

  // IoC container provided from App.ts is injected here
  @Inject('container')
  private container!: Container;

  created(): void {
    this.flightPathService = this.container.get<FlightPathServiceInterface>(SERVICE_IDENTIFIER.FLIGHT_PATH);
    this.flightPathAdminService = this.container.get<FlightPathAdminServiceInterface>(SERVICE_IDENTIFIER.FLIGHT_PATH_ADMIN);
  }

  // Class properties will be component data
  onResize() {
    this.windowSize = { x: window.innerWidth, y: window.innerHeight };
  }

  async mounted() {
    this.onResize();
    if (this.flightPathService != undefined) await this.flightPathService.updateToken();
    if (this.flightPathAdminService != undefined) await this.flightPathAdminService.updateToken();
  }
}
