import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!!_vm.value)?_c(VDialog,{attrs:{"max-width":"600px","persistent":""},on:{"input":function($event){return _vm.$emit('input', $event)}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,{},[_vm._v("Revoke Certificate")]),_c(VCardText,{staticClass:"pb-1"},[_c('div',{staticClass:"text-container"},[_c('span',{staticClass:"title-text"},[_vm._v("Are you sure you want to "),_c('b',[_vm._v("REVOKE")]),_vm._v(" the certificate for "),_c('b',[_vm._v(_vm._s(_vm.machine.MachineName))]),_vm._v("? This action can not be undone.")])])]),_c(VForm,{staticClass:"ml-3 mr-3"},[_c(VTextField,{attrs:{"outlined":"","label":"To confirm revoking, enter revoke","rules":[function () { return !!_vm.confirmationText || 'Required'; }]},model:{value:(_vm.confirmationText),callback:function ($$v) {_vm.confirmationText=$$v},expression:"confirmationText"}})],1),_c('div',{staticClass:"text-container",attrs:{"align":"center","justify":"center"}},[_c(VIcon,{attrs:{"size":"30","color":"red"}},[_vm._v("mdi-alert")]),_c('span',{staticClass:"subtitle-text"},[_vm._v("This machine will not be able to send any data.")])],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('input', null)}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"text":"","disabled":_vm.confirmationText!='revoke'},on:{"click":function($event){return _vm.revoke(_vm.machine)}}},[_vm._v("Revoke")])],1)],1)],1):_vm._e(),_c(VOverlay,{attrs:{"opacity":0.5,"value":_vm.loading,"z-index":"300"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"42"}})],1),_c(VSnackbar,{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.snackBar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackBar),callback:function ($$v) {_vm.snackBar=$$v},expression:"snackBar"}},[_vm._v(" "+_vm._s(_vm.snackBarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }