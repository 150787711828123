import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[(_vm.signedIn == true)?_c(VAppBar,{attrs:{"app":"","dense":"","height":"48"}},[_c('h1',{staticClass:"ml-4 screen-title"},[_c(VIcon,{staticClass:"mr-3",attrs:{"color":"black","size":"30"},on:{"click":function($event){return _vm.$router.push('Home')}}},[_vm._v("mdi-home")]),_c('span',[_vm._v("Exxact FlightPath Administration / ")]),_c('span',[_vm._v(_vm._s(_vm.$router.currentRoute.name))])],1),_c(VSpacer),_c(VBtn,{attrs:{"link":"","text":"","small":"","to":{ name: 'About' }}},[_c(VAvatar,{staticStyle:{"border":"solid 1px rgba(0, 0, 0, 0.12)"},attrs:{"size":"32"}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-account")])],1),_vm._v(" "+_vm._s(_vm.userInfo != null ? _vm.userInfo.email : '')+" ")],1)],1):_vm._e(),_c(VMain,{staticStyle:{"background-color":"rgba(0, 0, 0, 0.12)"}},[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }