import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
// Translation provided by Vuetify (typescript)
import en from 'vuetify/src/locale/en';
import fr from 'vuetify/src/locale/fr';

Vue.use(Vuetify);
export default new Vuetify({
  icons: {
    iconfont: 'mdi' // default - only for display purposes
  },
  theme: {
    themes: {
      light: { primary: '#e34615' },
      dark: { primary: '#e34615' }
    }
  },
  lang: {
    locales: { en, fr },
    current: 'en'
  }
});
