const awsWeb = {
  aws_project_region: 'eu-west-3', // (optional) Default region for project
  aws_user_pools_id: 'eu-west-3_2y57QCxCX', // (optional) -  Amazon Cognito User Pool ID
  aws_cognito_identity_pool_id: 'eu-west-3:90b3c102-560c-4e71-8f3d-87d9984bfd90', // (optional) - Amazon Cognito Identity Pool ID
  aws_user_pools_web_client_id: '6960tvmocr1gqiq1c0uv5h5puq',
  oauth: {},
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: 'eu-west-3:90b3c102-560c-4e71-8f3d-87d9984bfd90',
    // REQUIRED - Amazon Cognito Region
    region: 'eu-west-3',
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'eu-west-3_2y57QCxCX',
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '6960tvmocr1gqiq1c0uv5h5puq'
  }
};

export default awsWeb;
