import { Auth } from '@aws-amplify/auth';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import About from '../views/About.vue';
import Accounts from "../views/Accounts.vue";
import AdminUsers from "../views/AdminUsers.vue";
import Authentication from '../views/Auth.vue';
import Home from '../views/Home.vue';
import Machines from "../views/Machines.vue";
import MachineCredentials from "../views/MachineCredentials.vue";
import { Account } from '@jca/flightpath-components/src/models/Account';
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/home',
    name: 'Home',
    component: Home,
    redirect: "/home/admins",
    meta: { requiresAuth: true, title: '' },
    children: [{
      "path": "admins",
      "name": "Admins",
      component: AdminUsers
    }, {
      "path": "accounts",
      "name": "Accounts",
      component: Accounts
    },{
      "path": "machinecredentials/:accountId/:accountName",  //"path": "machinecredentials/:accountId/:accountName",
      "name": "MachineCredentials",
      component: MachineCredentials,
      props: route => ({ account: {AccountId: `${route.params.accountId}`, Name: `${route.params.accountName}`} as Account})
    }, {
      "path": "machines",
      "name": "Machines",
      component: Machines
    }]
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Authentication,
    meta: { requiresAuth: false, title: '' }
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: { requiresAuth: false, title: '' }
  },
  {
    path: '*',
    redirect: '/home'
  }
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});

router.beforeResolve((to, from, next) => {
  let localMode = false;
  //todo move this into vuex
  if (localStorage.localMode) {
    localMode = localStorage.localMode == 'true';
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    Auth.currentAuthenticatedUser()
      .then(() => {
        next();
      })
      .catch(() => {
        if (localMode) {
          next();
        } else {
          next({
            path: '/auth'
          });
        }
      });
  }
  next();
});

export default router;
