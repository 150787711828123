export class SortHelper {
  static compareDates(a: Date | string, b: Date | string): number {
    const aTime = new Date(a).getTime();
    const bTime = new Date(b).getTime();

    if (aTime > bTime) {
      return 1;
    }
    if (aTime < bTime) {
      return -1;
    }
    return 0;
  }

  static compareStrings(a: string, b: string): number {
    if (a.toLowerCase() > b.toLowerCase()) {
      return 1;
    }
    if (b.toLowerCase() > a.toLowerCase()) {
      return -1;
    }
    return 0;
  }
}
