import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VImg,{staticClass:"backgroundHome",attrs:{"src":require("../assets/backgroundHome.png"),"srcset":"../assets/backgroundHome@2x.png 2x,\n           ../assets/backgroundHome@3x.png 3x"}},[_c(VContainer,{staticStyle:{"margin":"0","padding":"0"},attrs:{"fluid":""}},[_c(VRow,{staticStyle:{"height":"100vh","width":"100vw"},attrs:{"align":"center","justify":"center","dense":""}},[_c(VCol,{staticClass:"d-flex d-xl-flex d-sm-flex d-lg-flex d-md-flex flex-column justify-center align-center",attrs:{"cols":"12","sm":"5","xl":"6","lg":"6","md":"6"}},[_c('div',{staticClass:"text-center font-weight-medium text-caption text-sm-body-2 text-md-body-1 text-lg-h6 text-xl-h4"},[_vm._v("Exxact Admin Portal")])]),_c(VCol,{staticClass:"fill-height d-xl-flex d-sm-flex d-lg-flex d-md-flex flex-column justify-center align-right",staticStyle:{"background-color":"rgba(255, 255, 255, 0.6)"},attrs:{"cols":"12","sm":"7","xl":"6","lg":"6","md":"6"}},[_c(VRow,[_c(VCol,{staticClass:"d-xl-flex d-sm-flex d-lg-flex d-md-flex flex-column justify-center align-center",attrs:{"cols":"12"}},[(_vm.formState === 'signIn')?_c('flightpath-sign-in',{attrs:{"baseUrl":_vm.appConfigBaseUrl}}):_vm._e(),_c(VDialog,{attrs:{"persistent":"","max-width":"430px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"d-flex",attrs:{"text":"","color":"gray"}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-chevron-right")]),_vm._v(_vm._s(_vm.$t('auth.forgot_password')))],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c('flightpath-new-password',{attrs:{"dialog":_vm.dialog},on:{"update:dialog":function($event){_vm.dialog=$event}}})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }