













import { FlightPathAxios } from '@/services/api';
import Vue from 'vue';
import Component from 'vue-class-component';
import { MachineStatus } from '@/types/machine-status'
import { UpdateResponse } from '@/types/update-response'

// Define the component in class-style
/* eslint-disable @typescript-eslint/no-explicit-any */
@Component
export default class Machines extends Vue {
  machines: MachineStatus[] = [];
  headers = [
    { text: 'Machine ID', value: 'machineId', align: 'left' },
    { text: 'Machine Name', value: 'machineName', align: 'left' },
    { text: 'Installed Version', value: 'installedVersion', align: 'left' },
    { text: 'Install date', value: 'installDate', align: 'left' },
    { text: 'Available Version', value: 'availableVersion', align: 'left' }
  ];
  loading = true;
  formatDate = new Intl.DateTimeFormat("en-CA", { timeStyle: "short", dateStyle: "short", hour12: false });

  async mounted() {
    const response = await new FlightPathAxios().getInstance().get<UpdateResponse[]>(`/exxact/update-report`);
    this.machines = response.data.map(update => ({
      machineId: update.MachineSoftwareUpdate?.machine_id,
      machineName: update.MachineSoftwareUpdate?.machine_name,
      installedVersion: update.InstalledMachineConfig?.installed_version,
      installDate: update.InstalledMachineConfig ? this.formatDate.format(new Date(update.InstalledMachineConfig?.install_date)) : "",
      availableVersion: update.InstalledMachineConfig?.last_available_version,
    }));
    this.loading = false;
  }
}
