




























































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';
import { Container } from 'inversify';
import { FlightPathServiceInterface } from '@jca/flightpath-components/src/services/FlightPathServiceInterface';
import SERVICE_IDENTIFIER from '@jca/flightpath-components/src/services/identifiers';
import { Convert, Group, User } from '@jca/flightpath-components/src/models/CognitoUser';
import { Account } from '@jca/flightpath-components/src/models/Account';
import { Guid } from 'guid-typescript';
import { FlightPathAdminServiceInterface } from 'node_modules/@jca/flightpath-components/src/services/FlightPathAdminServiceInterface';
import DeleteAccount from '@/components/DeleteAccount.vue';

// Define the component in class-style
/* eslint-disable @typescript-eslint/no-explicit-any */
@Component({ components: { DeleteAccount } })
export default class Accounts extends Vue {
  loading = true;
  creatingAccount = false;
  windowSize = {
    x: 0,
    y: 0
  };
  accountOwners: User[] = [];
  accounts: Account[] = [];
  headers = [
    { text: 'Account Owner Email', value: 'OwnerEmail', align: 'left' },
    { text: 'Account Name', value: 'Name', align: 'left' },
    { text: 'Account Description', value: 'Description', align: 'left' },
    { text: 'Account Id', value: 'AccountId', align: 'left' },
    { text: '', value: 'actions', align: 'center', sortable: false }
  ];
  snackBar = false;
  snackBarText = '';
  newAdminAccountDialog = false;
  newAccountDialog = false;
  newAccountName = '';
  newAccountDescription = '';
  newAccountOwnerEmail = '';
  newAccountFormValid = '';
  deleteDialog = false;
  deleteAccountItem: Account | undefined = undefined;
  usersAttachedToAccount: string[] = [];

  async getUsers() {
    if (this.flightPathService != undefined) await this.flightPathService.updateToken();
    let token = '';
    this.accountOwners = [];

    try {
      do {
        const response = await this.flightPathService?.getAllUsersGroup(50, token, Group.AccountOwner);
        const groupResponse = Convert.toListUsersInGroupResponse(JSON.stringify(response?.data));
        token = groupResponse.NextToken ?? '';
        groupResponse.Users.forEach(user => {
          this.accountOwners.push(user);
        });
      } while (token != null && token != '');
    } catch (err) {
      console.error(err);
    }
  }

  async getAccounts() {
    if (this.flightPathAdminService != undefined) await this.flightPathAdminService.updateToken();

    try {
      const response = await this.flightPathAdminService?.getAllAccounts();
      this.accounts = JSON.parse(JSON.stringify(response?.data)) as Account[];
    } catch (err) {
      console.error(err);
    }
  }

  confirmDeleteAccount(item: Account) {
    this.deleteDialog = true;
    this.deleteAccountItem = item;
  }

  initialize() {
    this.loading = true;
    this.getUsers();
    this.getAccounts();
    this.loading = false;
    this.newAccountName = '';
    this.newAccountDescription = '';
    this.newAccountOwnerEmail = '';
  }

  userAttribute(user: User, attribute: string): string {
    let returnValue = '';
    user.Attributes?.forEach(value => {
      if (value.Name === attribute) {
        returnValue = value.Value;
      }
    });
    return returnValue;
  }

  getAccountOwnerEmail(account: Account): string {
    const owner = this.accountOwners.find(x => this.userAttribute(x, 'custom:accountid') == account.AccountId);
    if (owner == undefined) return '';
    return this.userAttribute(owner, 'email');
  }

  async createNewAccountAndOwner() {
    if (this.flightPathAdminService != undefined) await this.flightPathAdminService.updateToken();

    this.snackBarText = '';
    this.creatingAccount = true;
    const accountId = Guid.create().toString();

    const newAccount = {
      AccountId: accountId,
      Name: this.newAccountName,
      Description: this.newAccountDescription
    } as Account;

    try {
      const response = await this.flightPathAdminService?.addAccount(newAccount, this.newAccountOwnerEmail, Group.AccountOwner);
      this.snackBarText = this.$t('success') as string;
      console.log(response);
    } catch (err) {
      debugger;
      console.error(err.response.data);
      this.snackBarText = `${err.response.data}`;
    } finally {
      this.snackBar = true;
      this.newAccountDialog = false;
      this.creatingAccount = false;
    }

    await this.initialize();
  }

  viewMachines(item: Account) {
    this.$router.push({ name: 'MachineCredentials', params: { accountId: item.AccountId, accountName: item.Name } });
  }

  // @Inject()
  private flightPathService: FlightPathServiceInterface | undefined = undefined;
  private flightPathAdminService: FlightPathAdminServiceInterface | undefined = undefined;

  // IoC container provided from App.ts is injected here
  @Inject('container')
  private container!: Container;

  created(): void {
    this.flightPathService = this.container.get<FlightPathServiceInterface>(SERVICE_IDENTIFIER.FLIGHT_PATH);
    this.flightPathAdminService = this.container.get<FlightPathAdminServiceInterface>(SERVICE_IDENTIFIER.FLIGHT_PATH_ADMIN);
  }

  // Class properties will be component data
  onResize() {
    this.windowSize = { x: window.innerWidth, y: window.innerHeight };
  }

  async mounted() {
    this.onResize();
    if (this.flightPathService != undefined) await this.flightPathService.updateToken();
    if (this.flightPathAdminService != undefined) await this.flightPathAdminService.updateToken();
    this.initialize();
  }
}
