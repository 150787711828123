import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"600px","persistent":""},on:{"input":function($event){return _vm.$emit('input', $event)}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,{},[_vm._v(" Add New Machine")]),_c(VForm,{staticClass:"pa-5",model:{value:(_vm.newMachineFormValid),callback:function ($$v) {_vm.newMachineFormValid=$$v},expression:"newMachineFormValid"}},[_c(VTextField,{attrs:{"outlined":"","label":"Machine Name","rules":_vm.machineNameRules},model:{value:(_vm.newMachineName),callback:function ($$v) {_vm.newMachineName=$$v},expression:"newMachineName"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"text":"","disabled":!_vm.newMachineFormValid},on:{"click":function($event){return _vm.createNewMachineCredential()}}},[_vm._v("Save")])],1)],1)],1),_c(VOverlay,{attrs:{"opacity":0.5,"value":_vm.loading,"z-index":"300"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"42"}})],1),_c(VSnackbar,{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.snackBar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackBar),callback:function ($$v) {_vm.snackBar=$$v},expression:"snackBar"}},[_vm._v(" "+_vm._s(_vm.snackBarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }