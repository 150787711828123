




















































import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import GlobalStore from '@/store/modules/global';
import { addressRequired, addressValid } from '@/validate';
import { Auth } from '@aws-amplify/auth';
// Define the component in class-style
@Component
export default class About extends Vue {
  public globalStore = getModule(GlobalStore, this.$store);
  flightPathIp: string | null = null;
  valid = false;
  localMode = false;
  signedIn = false;
  addressRules: ((data: string) => void)[] = [addressRequired(), addressValid()];

  submit() {
    localStorage.flightPathIp = this.flightPathIp;
    localStorage.localMode = this.localMode;
  }
  beforeCreate() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        if (user != undefined) {
          this.signedIn = true;
        }
      })
      .catch(() => console.log('not signed in...'));
  }
  async signOut() {
    await Auth.signOut();
  }

  mounted() {
    if (localStorage.flightPathIp) {
      this.flightPathIp = localStorage.flightPathIp;
    }
    //todo move this into vuex
    if (localStorage.localMode) {
      this.localMode = localStorage.localMode == 'true';
    }
  }

  // props will be calculated properties
  get appVersion(): string | undefined {
    return this.globalStore.version;
  }
}
