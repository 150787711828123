import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4"},[_c(VDataTable,{attrs:{"headers":_vm.headers,"footer-props":{ itemsPerPageOptions: [10, 25] },"items":_vm.adminUsers,"options":_vm.options,"server-items-length":_vm.totalUsers,"loading":_vm.loading,"loading-text":"Loading... Please wait"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" ")]}},{key:"item.Email",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ml-10 ml-md-0"},[_vm._v(_vm._s(_vm.getEmail(item)))])]}},{key:"footer.prepend",fn:function(){return [_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){_vm.createNewFlightPathAdminDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t('create_new_fp_admin'))+" ")])]},proxy:true}])}),_c(VDialog,{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.createNewFlightPathAdminDialog),callback:function ($$v) {_vm.createNewFlightPathAdminDialog=$$v},expression:"createNewFlightPathAdminDialog"}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"headline"},[_vm._v(" Create New FlightPath Admin")]),_c(VForm,{staticClass:"pa-5",model:{value:(_vm.newFlightPathAdminFormValid),callback:function ($$v) {_vm.newFlightPathAdminFormValid=$$v},expression:"newFlightPathAdminFormValid"}},[_c(VTextField,{attrs:{"outlined":"","label":"Email","rules":[function () { return !!_vm.newFlightPathAdminEmail || 'Required'; }]},model:{value:(_vm.newFlightPathAdminEmail),callback:function ($$v) {_vm.newFlightPathAdminEmail=$$v},expression:"newFlightPathAdminEmail"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.createNewFlightPathAdminDialog = false}}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c(VBtn,{attrs:{"text":"","disabled":!_vm.newFlightPathAdminFormValid},on:{"click":function($event){return _vm.createNewFlightPathAdmin()}}},[_vm._v(_vm._s(_vm.$t('save'))+" ")])],1)],1)],1),_c(VOverlay,{attrs:{"opacity":0.5,"value":_vm.creatingAccount,"z-index":"300"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"42"}})],1),_c(VSnackbar,{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.snackBar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackBar),callback:function ($$v) {_vm.snackBar=$$v},expression:"snackBar"}},[_vm._v(" "+_vm._s(_vm.snackBarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }