



















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, Prop } from 'vue-property-decorator';
import { Container } from 'inversify';
import { FlightPathServiceInterface } from '@jca/flightpath-components/src/services/FlightPathServiceInterface';
import SERVICE_IDENTIFIER from '@jca/flightpath-components/src/services/identifiers';
import { MachineCredential, MachineCertificateStatus } from '@jca/flightpath-components/src/models/MachineCredential';
import { MachineCredentialCertificate } from '@jca/flightpath-components/src/models/MachineCredentialCertificate';
import { FlightPathAdminServiceInterface } from '@jca/flightpath-components/src/services/FlightPathAdminServiceInterface';
import { Guid } from 'guid-typescript';
import { AxiosError, AxiosResponse } from 'axios';
import { CertificateOperations } from '@/types/certificate-operations';

const confirmationMessage: Record<CertificateOperations, string> = {
    Create:  "To confirm the create action, enter \"create\"",
    Recreate:  "To confirm the recreate action, enter \"recreate\"",
    Revoke:  "To confirm the revoke action, enter \"revoke\""
}

// Define the component in class-style
/* eslint-disable @typescript-eslint/no-explicit-any */
@Component
export default class CreateCertificate extends Vue {
  @Prop() machine!: MachineCredential;
  @Prop() value!: boolean;
  
  operation: CertificateOperations | '' = '';
  message = '';
  loading = false;
  windowSize = {
    x: 0,
    y: 0
  };
  machineCredentialCertificate: MachineCredentialCertificate | null = null;
  snackBar = false;
  snackBarText = '';
  confirmationText = '';

  setOperation() {
     this.operation =  this.machine.CertificateStatus == MachineCertificateStatus.Active ? 'Recreate' : 'Create';
     this.message = this.machine.CertificateStatus == MachineCertificateStatus.Active ? confirmationMessage.Recreate : confirmationMessage.Create;
  };


  async createCertificate() {
    if (this.flightPathAdminService != undefined) await this.flightPathAdminService.updateToken();
    this.snackBarText = '';
    const machineId = Guid.createEmpty().toString();

    try {
      this.loading = true;
      const response = this.machine.CertificateStatus == MachineCertificateStatus.None 
      ? await this.flightPathAdminService?.AddMachineCredential(this.machine.AccountId, this.machine.MachineId, this.machine.MachineName)
      : await this.flightPathAdminService?.RecreateMachineCertificate(this.machine.AccountId, this.machine.MachineId, this.machine.MachineName);
      this.snackBarText = 'Success';
      this.machineCredentialCertificate = response?.data ? response.data : null;
      console.log(response);
      this.$emit('input', false);
    } catch (err: any) {
     this.errorHandler(err);
    } finally {
      this.loading = false;
      this.snackBar = true;
    }
    this.$emit('newmachinecredential', this.machineCredentialCertificate);
  }

  errorHandler(err: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response = (err as AxiosError).response as AxiosResponse<any>;
    const message = response.data;
    console.error(JSON.stringify(message));
    const displayMessage = Object.prototype.hasOwnProperty.call(message, 'error') ? message.error : message;
    this.snackBarText = `Error: ${displayMessage}`;
  }

  // @Inject()
  private flightPathService: FlightPathServiceInterface | undefined = undefined;
  private flightPathAdminService: FlightPathAdminServiceInterface | undefined = undefined;

  // IoC container provided from App.ts is injected here
  @Inject('container')
  private container!: Container;

  created(): void {
    this.flightPathService = this.container.get<FlightPathServiceInterface>(SERVICE_IDENTIFIER.FLIGHT_PATH);
    this.flightPathAdminService = this.container.get<FlightPathAdminServiceInterface>(SERVICE_IDENTIFIER.FLIGHT_PATH_ADMIN);
  }

  // Class properties will be component data
  onResize() {
    this.windowSize = { x: window.innerWidth, y: window.innerHeight };
  }

  async mounted() {
    this.onResize();
    this.setOperation();
    if (this.flightPathService != undefined) await this.flightPathService.updateToken();
    if (this.flightPathAdminService != undefined) await this.flightPathAdminService.updateToken();
  }
}
