























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, Prop } from 'vue-property-decorator';
import { Container } from 'inversify';
import { MachineCredentialCertificate } from '@jca/flightpath-components/src/models/MachineCredentialCertificate';

// Define the component in class-style
/* eslint-disable @typescript-eslint/no-explicit-any */
@Component
export default class MachineCertificate extends Vue {
  @Prop() value!: MachineCredentialCertificate | null;

  loading = false;
  creatingMachine = false;
  windowSize = {
    x: 0,
    y: 0
  };
  isDownloaded = false;

  downloadFile(url: string) {
    const link = document.createElement('a');
    link.href = url;
    link.click();
    setTimeout(() => {
      link?.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
      this.isDownloaded = true;
    }, 0);
  }

  // Class properties will be component data
  onResize() {
    this.windowSize = { x: window.innerWidth, y: window.innerHeight };
  }

  async mounted() {
    this.onResize();
  }
}
